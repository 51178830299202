import React , {Component} from 'react';
import Reveal from 'react-reveal/Reveal';
import emailjs from 'emailjs-com';
class FooterTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //message: 'mensaje',
            //name: 'Name',
            //email: 'info@ekortex.com'
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        var {fClass} = this.props;
        let FooterData = this.props.FooterData;
        return (
            <footer className={`footer_area footer_area_four f_bg ${fClass}`}>
                <div className="footer_top">
                    <div className="container">
                        <div className="row">
                            {
                                FooterData.CompanyWidget.map(widget => {
                                    return (
                                        <Reveal effect="fadeInUp"
                                                key={widget.id}>
                                            <div className="col-lg-3 col-md-6">
                                                <div
                                                    className="f_widget company_widget wow fadeInLeft"
                                                    data-wow-delay="0.2s">
                                                    <a href="index.html"
                                                       className="f-logo"><img
                                                        src={require('../../img/logo.png')}
                                                        alt="" width="325" height="49"/></a>
                                                    <div
                                                        className="widget-wrap">
                                                        <p className="f_400 f_p f_size_15 mb-0 l_height34">
                                                            <span>Email:</span>
                                                            <a href="mailto:info@ekortex.com"
                                                               className="f_400">info@ekortex.com</a>
                                                        </p>
                                                        {/* <p className="f_400 f_p f_size_15 mb-0 l_height34"><span>Tel AR:</span> <a href="tel:+542634517140" className="f_400">+54 263 451 7140</a></p>
                                                             <p className="f_400 f_p f_size_15 mb-0 l_height34"><span>Phone US:</span> <a href="tel:+16507970657" className="f_400">+1 650 797-0657‬</a></p> */}

                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="contact_form col-lg-9">
                                                <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">Contacto</h2>

                                                <div className="contact_form_box"
                                                      id="contactForm">
                                                    <div className="row">
                                                        <div
                                                            className="col-lg-6">
                                                            <div
                                                                className="form-group text_box">
                                                                <input
                                                                    type="text"
                                                                    id="name"
                                                                    name="name"
                                                                    placeholder="Nombre"
                                                                    value={this.state.name}
                                                                    onChange={this.handleChangeName}/>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="col-lg-6">
                                                            <div
                                                                className="form-group text_box">
                                                                <input
                                                                    type="text"
                                                                    name="email"
                                                                    id="email"
                                                                    placeholder="Email"
                                                                    onChange={this.handleChangeEmail}
                                                                    value={this.state.email}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="col-lg-12">
                                                            <div
                                                                className="form-group text_box">
                                                                        <textarea
                                                                            name="message"
                                                                            id="message"
                                                                            cols="30"
                                                                            rows="10"
                                                                            placeholder="Mensaje"
                                                                            value={this.state.message}
                                                                            onChange={this.handleChange}>

                                                                        </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button
                                                            className="btn_three"
                                                            onClick={this.handleSubmit}>Enviar
                                                    </button>
                                                </div>
                                                 <h3>
                                                    {this.state.messageStatus}
                                                </h3>

                                            </div>

                                        </Reveal>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="footer_bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-5 col-sm-6">
                                <p className="mb-0 f_400">{FooterData.copywrite}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }

    handleChange(event) {
        this.setState({message: event.target.value})
    }
    handleChangeEmail(event) {
        this.setState({email: event.target.value})
    }

    handleChangeName(event) {
        this.setState({name: event.target.value})
    }




    handleSubmit(event) {
        // console.log("submit");
        // console.log(this.state.message);
        // console.log(this.state.name);
        // console.log(this.state.email);
        const templateId = 'template_ep4xy0j';
     this.sendFeedback(templateId, {
         message: this.state.message,
         name: this.state.name,
         email: this.state.email
     })
    }

    sendFeedback(templateId, variables) {
        //console.log("sendear");
        emailjs.send('service_ylzws72', 'template_ep4xy0j', variables, 'user_Sb9k7KDCbr3mZtp4nSepp')
            .then((response) => {
                console.log('Email Enviador!', response.status, response.text);
                this.setState({message: ""})
                this.setState({name: ""})
                this.setState({email: ""})
                this.setState({messageStatus: "Mensaje Enviado"})
            }, (err) => {
                this.setState({messageStatus: "Error al enviar el mensaje, intente nuevamente o escriba a info@ekortex.com"})
                console.log('Fallo', err);
            });
    }
}
export default FooterTwo;
