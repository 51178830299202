import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import StartupBanner from '../components/Banner/StartupBanner';
import Stservice from '../components/Service/Sservice/StartupService';
import Features from '../components/Features/Features';
import Partner from '../components/Partner';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

const Startup = () => (
    <div className="body_wrapper">
        <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu"/>
        <StartupBanner/> 
        <Stservice/>
        <Features aClass="agency_featured_area_two"/>
        <Partner pClass="partner_logo_area bg_color"/>
        <FooterTwo FooterData={FooterData}/>
    </div>
)
export default Startup;