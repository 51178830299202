import React, {Component} from 'react';
import { BrowserRouter as Router, Switch} from 'react-router-dom';
/*------ Pages-----*/
import {Home} from './Pages/Home';
import DigitalMarketing from './Pages/Digital-marketing';
import PaymentProcessing from './Pages/Payment-processing';
import HRManagement from './Pages/HR-Management';
import Startup from './Pages/Startup';
import HomeCRM from './Pages/Home-CRM';
import About from './Pages/About';
import Service from './Pages/Service';
import Process from './Pages/Process';
import Team from './Pages/Team';
import Portfolio2col from './Pages/Portfolio-2col';
import Portfolio3col from './Pages/Portfolio-3col';
import Portfoliofull4col from './Pages/Portfolio-fullwidth-4col';
import PortfolioSingle from './Pages/PortfolioSingle';
import Bloglist from './Pages/Bloglist';
import BlogSingle from './Pages/BlogSingle';
import Contact from './Pages/Contact';
import ScrollToTopRoute from './ScrollToTopRoute';
import Landing from './Pages/Landing';

class App extends Component{
    componentDidMount() {
      this.props.hideLoader();
  }
  render(){
    return(
        <Router>
           <Switch>
             <ScrollToTopRoute exact={true} path={'/'} component={Startup} />
             <ScrollToTopRoute path="/contact" component={Contact} />
           </Switch>
        </Router>
    );
  }
}

export default App;

