import React, {Component} from 'react';
import Startupsitem from './Startupsitem';
import Reveal from 'react-reveal';
class Stservice extends Component {
    render(){
        return(
            <section className="stratup_service_area sec_pad bg_color">
                <div className="container">
                    <div className="sec_title mb_70 wow fadeInUp" data-wow-delay="0.4s">
                       <Reveal effect="fadeInUp"><h2 className="f_p f_size_30 l_height40 f_600 t_color text-center">Conocé <b>Transivo</b> <br/>Nuestro Software de Gestión de Trámites de importación y exportación
                       </h2></Reveal>
                    </div>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="stratup_service_img">
                                <div className="shape"></div>
                                <img className="laptop_img" src={require('../../../img/new/laptop.png')} alt=""/>
                            </div>
                        </div>
                        
                        <div className="col-lg-3 d-flex align-items-center">
                            <div className="stratup_service_info">
                                <Startupsitem Sicon="icon-paper-clip" text="Gestión de Trámites" description="Todos los trámites al alcance de tu mano"/>
                                <Startupsitem iconClass="icon_two" Sicon="icon-screen-desktop" text="Web" description="Disponible para acceder de forma remota."/>
                                <Startupsitem iconClass="icon_three" Sicon="icon-layers" text="Reportes" description="Reportes y exportación en todas las pantallas."/>
                                <Startupsitem iconClass="icon_four" Sicon="icon-cloud-download" text="Cloud" description="El sistema funciona en la nube, sin la necesidad de instalación de un servidor local."/>
                                <Startupsitem Sicon="icon-lock" text="Seguro" description="Seguridad integrada y backups automáticos."/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Stservice;