import React, {Component} from 'react';
import {Link} from 'react-scroll';
import Sticky from 'react-stickynode';

class OnepageMenu extends Component {
    render() {
        var {mClass, nClass, cClass, slogo, hbtnClass} =this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <header className="header_area">
                </header>
            </Sticky>
        );
    }
}

export default OnepageMenu;